import "../../../styles/uniqueNews.css";
//import Slider from 'react-touch-drag-slider'
//import {Helmet} from "react-helmet";
//import  {  Helmet ,  HelmetProvider  }  from  'react-helmet-async' ;
import React from "react";
import api from "../../../services/api";
import CarouselIMG from "../img/CarouselIMG";
import Loading from "../others/Loading";
import { imagemURL } from "../../../services/variables";
import Footer from "../others/Footer";
export default class showNewsSytem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      newsLast: [],
      dataFormat: "",
      renderLoading: true,
      multiImg: [],
    };
  }

  componentDidMount() {
    api
      .post(`/news/unique/${this.props.slugNewsSystem}`)
      .then((res) => {
        const result = res.data;
        if (result.err) {
          //this.setState({renderLoading: false});
          //this.setState({renderLoading: false});
        } else {
          console.log(result);
          this.setState({ newsLast: result.res, renderLoading: false });
        }
      })
      .catch((err) => console.log("erro, DB"));
  }

  render() {
    return (
      <>
        {this.state.renderLoading ? (
          <Loading />
        ) : (
          <>
            {this.state.newsLast.map((news, i) => (
              <div className="card-unique-all" key={i}>
                <div className="section-unique-title">
                  <h2>
                    <img
                      className="icon-title"
                      alt="notícias"
                      src="/icons/seta-direita.svg"
                    />
                    {news.TITULO}
                  </h2>
                </div>
                <div>
                  <hr className="hr-news-unique"></hr>
                  {news.IMG != "logo.jpg" ? (
                    <></>
                  ) : (
                    <>
                      <br />
                      <br />
                      <br />
                    </>
                  )}
                </div>
                {this.props.slugNewsSystem === "municipio-mapa" && (
                  <div className="container-mapa">
                    <iframe
                      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d253566.27857436257!2d-44.0413771!3d-6.773128!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x781463cfd52aaab%3A0xec972e2a6afaecdd!2sNova%20Iorque%20-%20MA!5e0!3m2!1spt-BR!2sbr!4v1716469200417!5m2!1spt-BR!2sbr"
                      allowfullscreen=""
                      loading="lazy"
                      className="mapa-localizacao"
                      referrerpolicy="no-referrer-when-downgrade"
                    ></iframe>
                  </div>
                )}
                <div className="section-unique-news">
                  <div className="cards-unique-news" key={i}>
                    {news.IMG == "logo.jpg" ? (
                      <></>
                    ) : (
                      <div>
                        <img
                          alt="imagem da notícia"
                          src={
                            news.IMG == "logo.jpg"
                              ? "/imagens/logo.png"
                              : imagemURL + news.IMG
                          }
                          className="card-unique-img"
                        />
                      </div>
                    )}
                    <div className="card-unique-txt">
                      <div className="title-unique-body"></div>
                      <div
                        className="news-unique-body"
                        dangerouslySetInnerHTML={{ __html: news.CORPO_TEXTO }}
                      ></div>
                    </div>
                  </div>
                  <CarouselIMG img={news.MULTIPLE_IMG} />
                </div>
                {news.IMG != "logo.jpg" ? (
                  <></>
                ) : (
                  <>
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                  </>
                )}
              </div>
            ))}
          </>
        )}
      </>
    );
  }
}
